import StatusBadge from "@/components/StatusBadge"
import Sidebar, { SidebarMenu, SidebarMenuLink, SidebarMenuButton } from "@/components/interface/Sidebar"
import { DEVICE_CONNECTED, DEVICE_SETTINGS_URL, FIRMWARE_URL } from "@/constants"
import { useSerialDevice } from "@/contexts/serial-device"
import {
  FIRMWARE_ERROR,
  FIRMWARE_OUTDATED,
  FIRMWARE_UP_TO_DATE,
  FIRMWARE_LOADING,
  useFirmware,
} from "@/hooks/firmware"
import { ReactComponent as ElementIcon } from "@/icons/element-icon.svg"
import { ReactComponent as ToolIcon } from "@/icons/tool-icon.svg"
import { ReactComponent as ResetIcon } from "@/icons/reset-icon.svg"
import { DEFAULT_SETTINGS } from "@/settings"
import { toSettingsUpdate } from "@/utils/device"
import { toast } from "react-toastify"
import { useLocation } from "react-router-dom"

const DEVICE_SETTINGS_TOAST_ID = "device-settings-toast"

const SettingsSidebar = () => {
  const device = useSerialDevice()
  const { firmwareStatus, latestFirmware, deviceFirmwareVersion } = useFirmware()
  const location = useLocation()

  // Check if we're in the process of updating firmware
  const isFirmwareUpdating = location.pathname === FIRMWARE_URL && 
    document.querySelector('[class*="type-body-lg mt-5"]')?.textContent?.includes('firmware')

  const onFactoryResetClicked = async () => {
    const confirmation = await window.confirm("Are you sure you want to reset factory settings")
    if (confirmation) {
      const factoryResetSettings = toSettingsUpdate(DEFAULT_SETTINGS)
      try {
        await device.updateSettings(factoryResetSettings)
        toast.success("Device reset to factory settings", {
          toastId: DEVICE_SETTINGS_TOAST_ID,
          updateId: DEVICE_SETTINGS_TOAST_ID,
        })
        // Reload the page to refresh all settings
        window.location.reload()
      } catch (error) {
        toast.error("Error resetting device")
      }
    }
  }

  return (
    <Sidebar>
      {device.status === DEVICE_CONNECTED && firmwareStatus !== FIRMWARE_LOADING && (
        <>
          <StatusWidget />
          <SidebarMenu>
            <SidebarMenuLink
              link={DEVICE_SETTINGS_URL}
              icon={<ElementIcon />}
              label="Element Settings"
            />
            <SidebarMenuLink
              link={FIRMWARE_URL}
              icon={<ToolIcon />}
              label={
                <>
                  <span>Firmware</span>
                  {firmwareStatus === FIRMWARE_ERROR && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-red">
                      Error loading version
                    </span>
                  )}
                  {firmwareStatus === FIRMWARE_UP_TO_DATE && !isFirmwareUpdating && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-bright-green">
                      Up to date (version {deviceFirmwareVersion})
                    </span>
                  )}
                  {firmwareStatus === FIRMWARE_OUTDATED && !isFirmwareUpdating && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-orange-400">
                      Outdated ({deviceFirmwareVersion} &raquo; {latestFirmware.version})
                    </span>
                  )}
                  {isFirmwareUpdating && (
                    <span className="type-body-sm ml-3 inline-block font-normal italic text-gray-400">
                      Version info pending refresh
                    </span>
                  )}
                </>
              }
            />
            <div className="my-4 border-b border-mine-shaft"></div>
            <SidebarMenuButton
              onClick={onFactoryResetClicked}
              icon={<ResetIcon className="text-white" />}
              label={<span className="text-white">Reset to Factory Defaults</span>}
              className="bg-red hover:bg-darker-red border-red"
            />
          </SidebarMenu>
        </>
      )}
    </Sidebar>
  )
}

const StatusWidget = () => {
  return (
    <div className="relative overflow-hidden rounded-lg">
      <img
        className="w-full"
        src={`${process.env.PUBLIC_URL}/element-4-thumb.jpg`}
        alt="Element IV"
      />
      <div className="type-heading-xs absolute left-5 top-4 font-bold">Element 4</div>
      <StatusBadge className="absolute right-2 top-3" small />
    </div>
  )
}

export default SettingsSidebar
