import "@/css/styles.css"

import { AuthProvider } from "@/contexts/auth"
import { SerialDeviceProvider } from "@/contexts/serial-device"
import { DebugProvider } from "@/contexts/debug"
import { NextUIProvider } from "@nextui-org/react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import MobileWarningOverlay from "./components/MobileWarningOverlay"
import routes from "./routes"

// Override the default toast methods to include custom durations
const originalError = toast.error;
const originalWarning = toast.warning;
toast.error = (message, options = {}) => originalError(message, { ...options, autoClose: 10000 });
toast.warning = (message, options = {}) => originalWarning(message, { ...options, autoClose: 5000 });

const el = document.getElementById("root")

const root = ReactDOM.createRoot(el)
root.render(
  <NextUIProvider>
    <DebugProvider>
      <SerialDeviceProvider>
        <AuthProvider>
          <RouterProvider router={createBrowserRouter(routes)} />
        </AuthProvider>
      </SerialDeviceProvider>
      <ToastContainer position="bottom-left" theme="colored" autoClose={2000} />
      <MobileWarningOverlay />
    </DebugProvider>
  </NextUIProvider>
)
