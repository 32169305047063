import { axios, useAuth } from "@/contexts/auth"
import { useEffect, useState } from "react"
import type { AutoSaveMode } from "@/components/interface/PresetHeader"

export type UpdateAutoSaveCallback = (mode: AutoSaveMode, currentTimer: NodeJS.Timeout | null, saveCallback: () => Promise<void>) => void

export function useUserSettings() {
  const [autoSaveMode, setAutoSaveMode] = useState<AutoSaveMode>("immediate")
  const [isLoading, setIsLoading] = useState(true)
  const { isAuthenticated, user, tokens } = useAuth()

  const updateAutoSaveMode: UpdateAutoSaveCallback = async (mode, currentTimer, saveCallback) => {
    // Always update local state
    setAutoSaveMode(mode)

    // If changing from a delayed mode to immediate, save any pending changes
    if (mode === "immediate" && currentTimer) {
      try {
        clearTimeout(currentTimer)
        await saveCallback()
      } catch (error) {
        console.error("Failed to execute save callback:", error)
      }
    }

    // Only persist to backend if authenticated
    if (isAuthenticated()) {
      try {
        const response = await axios.patch("/api/users/settings/update_settings/", {
          auto_save_mode: mode,
        })
        // Only check status if we got a response
        if (response?.data?.status !== 'success') {
          console.error("Failed to update auto save mode on server")
        }
      } catch (error) {
        // API server might be down, just log the error and continue
        console.error("Failed to update auto save mode:", error)
      }
    }
  }

  // Reset state when user logs out
  useEffect(() => {
    if (!isAuthenticated() || !user) {
      setAutoSaveMode("immediate")
      setIsLoading(false)
    }
  }, [isAuthenticated, user])

  // Fetch settings when user is authenticated
  useEffect(() => {
    const fetchSettings = async () => {
      if (isAuthenticated() && user && tokens.access) {
        setIsLoading(true)
        try {
          const response = await axios.get("/api/users/settings/")
          console.log("Fetched settings:", response.data)
          // Only update if we got valid data
          if (response?.data?.auto_save_mode) {
            setAutoSaveMode(response.data.auto_save_mode)
          }
        } catch (error) {
          // API server might be down, just log the error and continue with default
          console.error("Failed to fetch user settings:", error)
        } finally {
          setIsLoading(false)
        }
      }
    }

    fetchSettings()
  }, [isAuthenticated, user, tokens.access])

  return {
    autoSaveMode,
    isLoading,
    updateAutoSaveMode,
  }
} 