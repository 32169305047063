export const DEFAULT_SETTINGS = {
  Firmware: false,
  Configuration: {
    General: {
      "UAC Mode": {
        Elements: ["UAC1", "UAC2"],
        Current: "UAC2",
      },
      "Inactive Mute": {
        Elements: ["DISABLED", "ENABLED"],
        Current: "DISABLED",
      },
      "SPDIF mode":	{
				"Elements":	["ESS S/PDIF", "XMOS S/PDIF (DSP)"],
				"Current": "XMOS S/PDIF (DSP)"
			},
    },
    DAC: {
      "Max Sample Rate": {
        Elements: ["192000", "384000"],
        Current: "192000",
      },
      "Second Harmonic Compensation": {
        Min: -75,
        Max: 75,
        Current: -2,
      },
      "Third Harmonic Compensation": {
        Min: -75,
        Max: 75,
        Current: -27,
      },
      "SPDIF Deemphasis": {
        Elements: ["OFF", "AUTO"],
        Current: "OFF",
      },
      "DPLL Bandwidth": {
        Min: 1,
        Max: 15,
        Current: 7,
      },
      "DAC Filter": {
        Elements: ["FAST ROLLOFF", "SLOW ROLLOFF", "MIN PHASE"],
        Current: "FAST ROLLOFF",
      },
    },
    UI: {
      "Screen Timeout": {
        Elements: [
          "2 SECONDS",
          "3 SECONDS",
          "4 SECONDS",
          "5 SECONDS",
          "10 SECONDS",
          "30 SECONDS",
          "60 SECONDS",
          "90 SECONDS",
          "120 SECONDS",
          "NEVER",
        ],
        Current: "5 SECONDS",
      },
      "Screen Contrast": {
        Elements: ["LOW", "MEDIUM", "HIGH"],
        Current: "MEDIUM",
      },
      "LED Mode": {
        Elements: ["ON", "5 MIN STANDBY", "WITH SCREEN"],
        Current: "ON",
      },
      "Knob Speed": {
        Elements: ["FULL", "HALF"],
        Current: "FULL",
      },
      "Volume Steps": {
        Elements: ["0.5 dB", "1.0 dB"],
        Current: "0.5 dB",
      },
      "Auto Gain Speed": {
        Elements: ["DEFAULT", "AGGRESSIVE"],
        Current: "AGGRESSIVE",
      },
      "Knob Button": {
        Elements: ["TOGGLE INPUT", "TOGGLE OUTPUT", "MUTE (USB ONLY)", "MUTE (SPDIF ONLY)"],
        Current: "TOGGLE OUTPUT",
      },
    },
    DSP: {
      RCA: {
        Preamp: {
          Mode: {
            Elements: ["AUTO", "MANUAL"],
            Current: "AUTO",
          },
          Gain: {
            Min: -30,
            Max: 3,
            Current: 0,
          },
        },
        Lowshelf: {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 80,
          },
          Q: {
            Min: 0.1,
            Max: 3.0,
            Current: 0.707,
          },
        },
        "Peaking 1": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 300,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 2": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 600,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 3": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 1200,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 4": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 2400,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 5": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 4000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 6": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 6000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 7": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 8000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 8": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 10000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        Highshelf: {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 13000,
            Current: 12000,
          },
          Q: {
            Min: 0.1,
            Max: 3.0,
            Current: 0.707,
          },
        },
      },
      Headphone: {
        Preamp: {
          Mode: {
            Elements: ["AUTO", "MANUAL"],
            Current: "AUTO",
          },
          Gain: {
            Min: -30,
            Max: 3,
            Current: 0,
          },
        },
        Lowshelf: {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 80,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 1": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 300,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 2": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 600,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 3": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 1200,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 4": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 2400,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 5": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 4000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 6": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 6000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 7": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 8000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        "Peaking 8": {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 10000,
          },
          Q: {
            Min: 0.1,
            Max: 50,
            Current: 0.707,
          },
        },
        Highshelf: {
          Gain: {
            Min: -30,
            Max: 30,
            Current: 0,
          },
          Frequency: {
            Min: 20,
            Max: 20000,
            Current: 13000,
          },
          Q: {
            Min: 0.1,
            Max: 3.0,
            Current: 0.707,
          },
        },
      },
    },
  },
}
