import cx from "classnames"
import Container from "@/components/Container"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import DebugTerminal from "./DebugTerminal"

type Props = {
  showBackground?: boolean
}

const Footer = ({ showBackground }: Props) => {
  const [version, setVersion] = useState<string>("")
  const currentYear = new Date().getFullYear()

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/version.json")
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`)
        }
        return res.json()
      })
      .then((data) => {
        console.log("Version data received:", data)
        if (!data?.version) {
          throw new Error("No version found in version data")
        }
        setVersion(data.version.slice(0, 7))
      })
      .catch((error) => {
        console.error("Failed to fetch version:", error)
        setVersion("dev")
      })
  }, [])

  return (
    <>
      <footer
        className={cx("z-10 w-full flex-shrink-0 border-t border-mine-shaft relative", {
          "bg-dark": !showBackground,
        })}
      >
        <Container>
          <div className="type-body-base flex h-14 items-center justify-between">
            <div className="flex items-center gap-2">
              <span>© {currentYear} JDS Labs, Inc</span>
              <span>|</span>
              <Link to="/privacy-policy" className="hover:underline">
                Privacy
              </Link>
              <span>|</span>
              <Link to="/faq" className="hover:underline">
                FAQ
              </Link>
              <span>|</span>
              <a
                href="https://jdslabs.com/support/contact"
                className="hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Support
              </a>
            </div>
            <div className="flex items-center gap-4">
              <DebugTerminal />
              {version && (
                <Link to="/changelog" className="text-sm text-gray-500 hover:underline">
                  App version {version}
                </Link>
              )}
            </div>
          </div>
        </Container>
      </footer>
    </>
  )
}

export default Footer
