import { useEffect, useRef } from "react"

const createDeferredPromise = () => {
  let resolve, reject
  const promise = new Promise((res, rej) => {
    ;[resolve, reject] = [res, rej]
  })
  return { promise, reject, resolve }
}

const useQueue = (props) => {
  const items = useRef([])
  const isProcessing = useRef(false)
  const maxSize = "maxSize" in props ? props.maxSize : 5
  const pollTime = "pollTime" in props ? props.pollTime : 500
  const subscribers = useRef({})

  // Subscribe to queue
  const subscribe = (name, callback) => {
    if (name in subscribers.current) {
      console.debug(`Queue: '${name}' already subscribed to queue`)
      return
    }
    subscribers.current[name] = callback
    console.debug(`Queue: '${name}' subscribed to queue`)
    return () => {
      console.debug(`Queue: '${name}' unsubscribed from queue`)
      //   subscribers.current = subscribers.current.filter((cb) => cb !== callback)
    }
  }

  // Add an item to the queue
  const push = (item) => {
    const deferredPromise = createDeferredPromise()
    if (items.current.length >= maxSize) {
      console.debug(`Queue: queue is full (max size is ${maxSize})`)
      return false
    }
    console.debug(`Queue: item added to the command queue:`, item)
    items.current.push([item, deferredPromise])
    return deferredPromise
  }

  // Clear all items from the queue
  const clear = () => {
    console.debug(`Queue: clearing ${items.current.length} items from queue`)
    items.current = []
  }

  // Process the next item in the queue (if it exists)
  const process = async () => {
    if (isProcessing.current) {
      console.debug("Queue: previous command is still being executed")
      return
    }

    if (items.current.length === 0) {
      return
    }

    isProcessing.current = true

    const [nextItem, deferredPromise] = items.current.shift()

    if (subscribers.current && Object.keys(subscribers.current).length) {
      for (const [name, callback] of Object.entries(subscribers.current)) {
        await callback(nextItem, deferredPromise)
      }
    }

    isProcessing.current = false
  }

  // Poll queue for new items and process them
  useEffect(() => {
    const interval = setInterval(() => {
      process()
    }, pollTime) // Poll every second
    return () => clearInterval(interval) // Cleanup on unmount
  }, [])

  return {
    push,
    subscribe,
    clear,
  }
}

export { useQueue }
