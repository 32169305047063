import Footer from "@/components/interface/Footer"
import Header from "@/components/interface/Header"
import { ReactNode } from "react"
import { useDebug } from "@/contexts/debug"
import cx from "classnames"

type Props = {
  children: ReactNode
  showBackground?: boolean
}

const Layout = ({ children, showBackground = false }: Props) => {
  const { isTerminalOpen } = useDebug()

  return (
    <div className="flex h-screen max-h-screen w-screen flex-col overflow-hidden">
      <Header showBackground={showBackground} />
      <main className={cx("flex-1 overflow-y-auto transition-all duration-200", {
        "mb-80": isTerminalOpen // Add margin to make room for terminal
      })}>
        {children}
      </main>
      <Footer showBackground={showBackground} />
    </div>
  )
}

export default Layout
