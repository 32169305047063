import { createContext, useContext, useEffect, useState } from "react"
import { useSerial } from "@/hooks/serial"
import { useQueue } from "@/hooks/queue"
import { toast } from "react-toastify"
import { DEFAULT_SETTINGS } from "@/settings"

const DEVICE_UPDATE_TOAST_ID = "device-update-toast"

class SerialDeviceError extends Error {}

interface SerialDeviceContextType {
  status: string
  request: () => Promise<boolean>
  getSettings: () => Promise<any>
  saveSettings: () => Promise<any>
  updateSettings: (settings: any) => Promise<any>
  auditionSettings: (settings: any) => Promise<any>
  refreshSettings?: () => Promise<void>
  portRef: React.MutableRefObject<any>
  disconnect: () => Promise<void>
  dspSettings: any
  setDspSettings: (settings: any) => void
  cachedGains: Record<string, number>
  setCachedGains: (gains: Record<string, number>) => void
}

const SerialDeviceContext = createContext<SerialDeviceContextType | undefined>(undefined)

export function SerialDeviceProvider({ children }) {
  const serial = useSerial()
  const queue = useQueue({ maxSize: 10, pollTime: 200 })
  const [dspSettings, setDspSettings] = useState(DEFAULT_SETTINGS["Configuration"]["DSP"])
  const [cachedGains, setCachedGains] = useState<Record<string, number>>({})

  /**
   * Execute a command when received from the queue
   *
   * @param command
   * @param deferredPromise
   * @returns
   */
  const onCommandReceived = async (command, deferredPromise) => {
    console.debug(`Device: executing command:`, command)
    return new Promise<void>((resolve) => {
      let timeout

      // Subscribe to serial port to read the next message
      const unsubscribe = serial.subscribe((data) => {
        clearTimeout(timeout)
        unsubscribe()
        deferredPromise.resolve(data)
        resolve()
      })

      // Set a timeout
      timeout = setTimeout(() => {
        console.warn("Device: command timed out")
        unsubscribe()
        deferredPromise(false)
        resolve()
      }, 5000)

      // Send command
      serial.write(command)
    })
  }

  const sendCommand = async (command) => {
    // Push command to the queue
    const onResponseReceived = await queue.push(command)
    
    if (!onResponseReceived) {
      throw new SerialDeviceError("Failed to queue command")
    }

    // Wait for reply from the serial device
    const response = await onResponseReceived.promise
    if (response["Status"] === true) {
      return response
    } else {
      throw new SerialDeviceError("Command error updating settings")
    }
  }

  const getSettings = async () => {
    return await sendCommand({
      Product: "JDS Labs Element IV",
      Action: "Describe",
    })
  }

  const saveSettings = async (): Promise<any> => {
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Save",
    })
    if (response["Status"] === true) {
      toast.success("Settings saved to flash storage", {
        toastId: DEVICE_UPDATE_TOAST_ID,
        updateId: DEVICE_UPDATE_TOAST_ID,
      })
      return response
    } else {
      throw new SerialDeviceError("Command error saving settings")
    }
  }

  const auditionSettings = async (settingsUpdate: any): Promise<any> => {
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Audition",
      ...settingsUpdate,
    })
    if (response["Status"] === true) {
      toast.success("Settings Applied, unsaved", {
        toastId: DEVICE_UPDATE_TOAST_ID,
        updateId: DEVICE_UPDATE_TOAST_ID,
      })
      return response
    } else {
      throw new SerialDeviceError("Command error auditioning settings")
    }
  }

  /**
   * Send an update to the device
   *
   * @param partialSetting a nested object that contains only the settings we
   * want to update, for example:
   * {
   *   "UI": {
   *     "Screen Timeout": "5 Seconds"
   *   }
   * }
   * @returns
   */
  const updateSettings = async (settingsUpdate: any): Promise<any> => {
    const response = await sendCommand({
      Product: "JDS Labs Element IV",
      "Format Output": true,
      Action: "Update",
      ...settingsUpdate,
    })
    if (response["Status"] === true) {
      toast.success("Settings Applied & Saved", {
        toastId: DEVICE_UPDATE_TOAST_ID,
        updateId: DEVICE_UPDATE_TOAST_ID,
      })
      return response
    } else {
      throw new SerialDeviceError("Command error updating settings")
    }
  }

  /**
   * Subscribe to the queue and hook up callback for new commands
   *
   */
  useEffect(() => {
    const unsubscribe = queue.subscribe("executor", onCommandReceived)

    // Subscribe to serial events
    const serialUnsubscribe = serial.subscribe((data) => {
      if (data.Action === "Disconnect") {
        // Clear any pending commands from the queue
        queue.clear()
        
        // Reset DSP settings to default values
        setDspSettings(DEFAULT_SETTINGS["Configuration"]["DSP"])
        
        // Clear any cached gains
        setCachedGains({})
      }
    })

    return () => {
      if (unsubscribe) unsubscribe()
      if (serialUnsubscribe) serialUnsubscribe()
    }
  }, [])

  return (
    <SerialDeviceContext.Provider
      value={{ 
        ...serial, 
        getSettings, 
        updateSettings, 
        saveSettings, 
        auditionSettings,
        portRef: serial.portRef,
        dspSettings,
        setDspSettings,
        cachedGains,
        setCachedGains
      }}
    >
      {children}
    </SerialDeviceContext.Provider>
  )
}

export function useSerialDevice() {
  const context = useContext(SerialDeviceContext)
  if (!context) {
    throw new Error("useSerialDevice must be used within SerialDeviceProvider")
  }
  return context
}
