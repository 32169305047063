import { createContext, useContext, useState, useCallback } from 'react'

type LogLevel = 'debug' | 'info' | 'warn' | 'error'

export interface LogEntry {
  timestamp: string
  level: LogLevel
  message: string
  data?: any
}

interface DebugContextType {
  logs: LogEntry[]
  isTerminalOpen: boolean
  setTerminalOpen: (open: boolean) => void
  log: (level: LogLevel, message: string, data?: any) => void
  clearLogs: () => void
}

const DebugContext = createContext<DebugContextType | undefined>(undefined)

const MAX_LOGS = 1000

export function DebugProvider({ children }) {
  const [logs, setLogs] = useState<LogEntry[]>([])
  const [isTerminalOpen, setTerminalOpen] = useState(false)

  const log = useCallback((level: LogLevel, message: string, data?: any) => {
    const timestamp = new Date().toISOString().split('T')[1].slice(0, -1)
    setLogs(prevLogs => {
      const newLogs = [...prevLogs, { timestamp, level, message, data }]
      return newLogs.slice(-MAX_LOGS) // Keep only the last MAX_LOGS entries
    })

    // Also log to console for development
    switch (level) {
      case 'debug':
        console.debug(message, data)
        break
      case 'info':
        console.info(message, data)
        break
      case 'warn':
        console.warn(message, data)
        break
      case 'error':
        console.error(message, data)
        break
    }
  }, [])

  const clearLogs = useCallback(() => {
    setLogs([])
  }, [])

  return (
    <DebugContext.Provider 
      value={{ 
        logs, 
        isTerminalOpen, 
        setTerminalOpen, 
        log,
        clearLogs
      }}
    >
      {children}
    </DebugContext.Provider>
  )
}

export function useDebug() {
  const context = useContext(DebugContext)
  if (!context) {
    throw new Error('useDebug must be used within DebugProvider')
  }
  return context
} 