import { useState, useEffect } from "react"
import cx from "classnames"

import NumberInput from "@/components/forms/NumberInput"
import PowerToggle from "@/components/forms/PowerToggle"
import Label from "@/components/forms/Label"

const EqualizerTable = ({ eqSettings, onEqChange, mode }) => {
  const [bands, setBands] = useState([])
  const [cachedGains, setCachedGains] = useState<CachedGains>({})

  /**
   * On settings change
   *
   * When parent settings change, update the bands
   */
  useEffect(() => {
    if (eqSettings) {
      setBands(Object.entries(eqSettings).filter(([key, value]) => key !== "Preamp"))
    }
  }, [eqSettings, mode])

  /**
   * Update
   *
   * @param {*} band
   * @param {*} domain
   * @param {*} value
   * @returns
   */
  const update = async (band: [string, any], domain: string, value: number) => {
    const bandName = band[0]
    const success = await onEqChange([[bandName, domain, value]])
    return success
  }

  /**
   * Disable a band, caching its current gain value
   */
  const disableBand = async (band: [string, any]) => {
    const bandName = band[0]
    const currentGain = parseFloat(band[1]["Gain"]["Current"])
    
    // Cache the current gain before disabling
    setCachedGains(prev => ({
      ...prev,
      [bandName]: currentGain
    }))
    
    return await update(band, "Gain", 0)
  }

  const enableBand = async (band: [string, any]) => {
    const bandName = band[0]
    const cachedGain = cachedGains[bandName]
    
    // Only proceed if we have a cached value
    if (typeof cachedGain === 'number') {
      const success = await update(band, "Gain", cachedGain)
      
      // Clear cached gain after successful re-enable
      if (success) {
        setCachedGains(prev => {
          const newCache = { ...prev }
          delete newCache[bandName]
          return newCache
        })
      }
      return success
    }
    return false
  }

  return (
    <div className="min-w-300">
      {bands.length && (
        <div className={`grid grid-cols-${bands.length + 1} gap-x-5 gap-y-3`}>
          {bands.map((band, i) => {
            const domain = band[1]["Gain"]
            const value = parseFloat(domain["Current"])
            const isActive = value !== 0.0
            const bandName = band[0]
            const hasCache = typeof cachedGains[bandName] === 'number'
            
            return (
              <Band
                key={`label-${i}`}
                name={bandName}
                number={i + 1}
                isActive={isActive}
                canEnable={!isActive && hasCache}
                onToggle={async () => {
                  if (isActive) {
                    await disableBand(band)
                  } else {
                    await enableBand(band)
                  }
                }}
              />
            )
          })}
          <Label text="Gain" />
          {bands.map((band, i) => {
            const domain = band[1]["Gain"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`gain-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 1}
                min={min}
                max={max}
                step="0.1"
                onDebouncedChange={(value) => {
                  return update(band, "Gain", value)
                }}
              />
            )
          })}
          <Label text="Freq" />
          {bands.map((band, i) => {
            const domain = band[1]["Frequency"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`freq-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 2}
                min={min}
                max={max}
                onDebouncedChange={(value) => {
                  return update(band, "Frequency", value)
                }}
              />
            )
          })}
          <Label
            text="Q"
            info={
              <div className="max-w-md">
                Controls the width of the frequency range affected by the filter. A higher Q focuses
                on a narrower range, while a lower Q affects a wider range.
              </div>
            }
          />
          {bands.map((band, i) => {
            const domain = band[1]["Q"]
            const value = parseFloat(domain["Current"])
            const min = parseFloat(domain["Min"])
            const max = parseFloat(domain["Max"])
            return (
              <NumberInput
                key={`q-${i}-${value}`}
                value={value}
                tabIndex={i * 3 + 3}
                min={min}
                max={max}
                step="0.01"
                onDebouncedChange={(value) => {
                  return update(band, "Q", value)
                }}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

const Band = ({ className = "", name, number, isActive, canEnable, onToggle }) => {
  const getDisplayName = (name: string) => {
    // If it's a Peaking filter, just return "Peaking"
    if (name.startsWith("Peaking")) {
      return "Peaking"
    }
    return name
  }

  return (
    <div className={cx("pb-1", number === 1 ? "col-start-2" : "", className)}>
      <div className="type-body-xs font-medium">{getDisplayName(name)}</div>
      <div className="type-body-base mt-1 flex items-center font-bold">
        Band {number}
        <PowerToggle 
          isActive={isActive} 
          canEnable={canEnable}
          disable={onToggle}
        />
      </div>
    </div>
  )
}

export default EqualizerTable
