import { useSerialDevice } from "@/contexts/serial-device"
import { 
  DEVICE_CONNECTED, 
  DEVICE_DISCONNECTED, 
  DEVICE_UNSUPPORTED, 
  NO_DEVICES,
  JDS_LABS_DEVICE_IDS 
} from "@/constants"
import PropTypes from "prop-types"
import cx from "classnames"
import { useState } from "react"
import ConnectionDetailsModal from "@/components/modals/ConnectionDetailsModal"

const StatusDot = ({ small, className }) => {
  return (
    <div
      className={cx("rounded-full", className, {
        "mr-3 h-3 w-3": !small,
        "mr-2 h-2 w-2": small,
      })}
    ></div>
  )
}

const Unsupported = ({ small }) => {
  return (
    <>
      <StatusDot className="bg-yellow" small={small} />
      <span className="text-yellow">Unsupported Device</span>
    </>
  )
}

const Connected = ({ small, onClick }) => {
  return (
    <button onClick={onClick} className="flex items-center hover:opacity-80">
      <StatusDot className="bg-green" small={small} />
      <span>Connected</span>
    </button>
  )
}

const Disconnected = ({ small }) => {
  return (
    <>
      <StatusDot className="bg-red" small={small} />
      <span className="text-red">Disconnected</span>
    </>
  )
}

const StatusBadge = ({ className, small }) => {
  const serialDevice = useSerialDevice()
  const [showDetails, setShowDetails] = useState(false)

  const getDeviceName = () => {
    try {
      const portInfo = serialDevice.portRef?.current?.getInfo()
      return portInfo ? JDS_LABS_DEVICE_IDS[portInfo.usbProductId] : ''
    } catch (error) {
      return ''
    }
  }

  const getPortInfo = () => {
    try {
      return serialDevice.portRef?.current?.getInfo() || {}
    } catch (error) {
      return {}
    }
  }

  return (
    <>
      <div className={className}>
        <div
          className={cx("flex items-center border border-darkest-green pl-3 pr-4", {
            "type-body-base rounded-lg py-0.75 pb-0.75": !small,
            "type-body-xs rounded-full py-0.75 pb-0.75": small,
          })}
        >
          {serialDevice.status === DEVICE_UNSUPPORTED && <Unsupported small={small} />}
          {serialDevice.status === DEVICE_CONNECTED && (
            <Connected 
              small={small} 
              onClick={() => setShowDetails(true)}
            />
          )}
          {serialDevice.status === DEVICE_DISCONNECTED && <Disconnected small={small} />}
        </div>
      </div>

      <ConnectionDetailsModal
        isOpen={showDetails}
        onClose={() => setShowDetails(false)}
        deviceName={getDeviceName()}
        portInfo={getPortInfo()}
      />
    </>
  )
}

StatusBadge.propTypes = {
  className: PropTypes.string,
  small: PropTypes.bool,
}

export default StatusBadge
