import Button from "@/components/Button"
import { MODE_HEADPHONES, MODE_RCA } from "@/constants"
import { useAuth } from "@/contexts/auth"
import { ReactComponent as HeadphoneIcon } from "@/icons/headphone-icon.svg"
import { ReactComponent as RCAIcon } from "@/icons/rca-icon.svg"
import { ReactComponent as SearchIcon } from "@/icons/search-icon.svg"
import { ReactComponent as StarIcon } from "@/icons/star-icon.svg"
import WarningCallout from "@/components/WarningCallout"
import cx from "classnames"
import { Link, useNavigate } from "react-router-dom"
import { useState } from "react"

const ModePanel = ({
  mode,
  presets,
  activePreset,
  onModeChanged,
  onPresetSeleceted,
  onDisableEqClicked,
}) => {
  const { isAuthenticated } = useAuth()
  return (
    <>
      <ModePanelHeader mode={mode} onModeChanged={onModeChanged} />
      <section className="mt-6">
        {isAuthenticated() ? (
          <>
            <PresetsPanel
              presets={presets}
              activePreset={activePreset}
              mode={mode}
              onPresetSeleceted={onPresetSeleceted}
            />
          </>
        ) : (
          <LoggedOutWarning />
        )}
      </section>
      <ModePanelFooter onDisableEqClicked={onDisableEqClicked} />
    </>
  )
}

const ModePanelHeader = ({ mode, onModeChanged }) => {
  return (
    <header>
      <h3 className="type-heading-base">Mode</h3>
      <div className="mb-10 mt-1">
        <div className="flex">
          <button
            className="type-body-base clickable flex flex-1 items-center py-6 font-medium"
            onClick={() => {
              onModeChanged(MODE_HEADPHONES)
            }}
          >
            <HeadphoneIcon className="mr-4 w-5" />
            {MODE_HEADPHONES}
          </button>
          <button
            className="type-body-base clickable flex flex-1 items-center py-6 font-medium"
            onClick={() => {
              onModeChanged(MODE_RCA)
            }}
          >
            <RCAIcon className="mr-4 w-8" />
            {MODE_RCA}
          </button>
        </div>
        <div className="relative h-1 w-full overflow-hidden bg-tundora">
          <div
            className={cx("absolute top-0 h-full w-1/2 bg-red transition-all ease-out", {
              "-left-5": mode === MODE_HEADPHONES,
              "left-1/2": mode === MODE_RCA,
            })}
          ></div>
        </div>
      </div>
      <h4 className="type-heading-base font-bold">My presets</h4>
    </header>
  )
}

const ModePanelFooter = ({ onDisableEqClicked }) => {
  return (
    <footer>
      <Button
        className="mt-4"
        theme="primary"
        size="small"
        outline
        onClick={() => onDisableEqClicked()}
      >
        Clear All EQ Bands
      </Button>
    </footer>
  )
}

const PresetsPanel = ({ presets, activePreset, mode, onPresetSeleceted }) => {
  const [filterText, setFilterText] = useState("")

  const modePresets = presets
    ? presets.filter((preset) => preset.mode.toLowerCase() === mode.toLowerCase())
    : []

  const filteredModePresets = modePresets.filter((preset) =>
    preset.name.toLowerCase().includes(filterText.toLowerCase())
  )

  if (!presets) {
    return <></>
  }

  return (
    <div>
      {modePresets.length > 0 && (
        <div className="relative mb-5 mt-2">
          <SearchIcon className="absolute left-0 top-1/2 h-3 w-3 -translate-y-1/2 text-silver" />
          <input
            type="text"
            className="h-10 w-full border-b border-silver bg-transparent pl-5 transition-colors focus:border-white"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      )}
      <ul className="space-y-3">
        {modePresets.length === 0 && (
          <p className="text-sm font-normal text-silver">No presets saved</p>
        )}
        {modePresets.length > 0 && filterText.length > 0 && filteredModePresets.length === 0 && (
          <p className="text-sm font-normal text-silver">No presets found</p>
        )}
        {filteredModePresets.map((preset, index) => {
          return (
            <li key={index}>
              <button
                className={cx(
                  "type-body-sm clickable flex w-full items-center rounded-md border px-4 py-2 font-medium transition-colors",
                  activePreset && preset.id === activePreset.id
                    ? "border-red"
                    : "border-mine-shaft hover:border-silver"
                )}
                onClick={() => onPresetSeleceted(preset)}
              >
                {preset.is_favourite && <StarIcon className="mr-4 h-4 w-4 [&>path]:fill-yellow [&>path]:stroke-none" />}
                {preset.name}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const LoggedOutWarning = () => {
  const navigate = useNavigate()
  return (
    <WarningCallout
      heading={<h2>An account is required to save presets</h2>}
      content={
        <>
          <p>
            With account you can save presets directly to your device and access them via the
            Presets the panel here in the JDS Labs EQ.
          </p>
          <p>
            <Button
              theme="secondary"
              size="small"
              outline
              onClick={() => {
                navigate("/signup")
              }}
            >
              Create an account
            </Button>
          </p>
          <p className="text-xs">
            Already have an account?{" "}
            <Link className="font-bold underline" to={{ pathname: "/login" }}>
              Sign in
            </Link>
          </p>
        </>
      }
    ></WarningCallout>
  )
}

export default ModePanel
