import Modal from "@/components/modals/Modal"
import { ModalBody, ModalContent } from "@nextui-org/react"
import Button from "@/components/Button"
import { useSerialDevice } from "@/contexts/serial-device"

type Props = {
  isOpen: boolean
  onClose: () => void
  deviceName: string
  portInfo: any
}

const ConnectionDetailsModal = ({ isOpen, onClose, deviceName, portInfo }: Props) => {
  const { disconnect } = useSerialDevice()

  const handleDisconnect = async () => {
    await disconnect()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent className="max-w-lg">
        {() => (
          <ModalBody>
            <div className="py-4">
              <h3 className="type-heading-lg mb-4 font-bold">Connection Details</h3>
              <div className="space-y-2">
                <div>
                  <span className="font-bold">Device:</span> {deviceName} 
                  {portInfo?.usbVendorId && portInfo?.usbProductId && (
                    <span className="text-spun-pearl">
                      {" - "}VID 0x{portInfo.usbVendorId.toString(16)}, PID 0x{portInfo.usbProductId.toString(16)}
                    </span>
                  )}
                </div>
                {portInfo?.usbClass !== undefined && (
                  <div>
                    <span className="font-bold">USB Class:</span> {portInfo.usbClass}
                  </div>
                )}
                {portInfo?.usbSubclass !== undefined && (
                  <div>
                    <span className="font-bold">USB Subclass:</span> {portInfo.usbSubclass}
                  </div>
                )}
                {portInfo?.usbProtocol !== undefined && (
                  <div>
                    <span className="font-bold">USB Protocol:</span> {portInfo.usbProtocol}
                  </div>
                )}
                <div>
                  <span className="font-bold">Baud Rate:</span> 19200
                </div>
                <div className="mt-6 flex justify-end">
                  <Button 
                    theme="primary" 
                    outline
                    onClick={handleDisconnect}
                    className="w-auto"
                    full={false}
                  >
                    Disconnect Device
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ConnectionDetailsModal 