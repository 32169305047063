import Accordion from "@/components/Accordion"
import Layout from "@/components/layouts/DefaultLayout"
import { useState } from "react"

/**
 * Linux instructions component moved from FirmwarePage to be reusable
 */
const LinuxUdevInstructions = () => {
  return (
    <div className="space-y-4">
      <p>To install firmware updates, you must grant permissions to Element IV's DFU interface. Follow these steps:</p>
      
      <ol className="list-decimal list-inside space-y-4">
        <li>
          <strong>Create the udev rule file:</strong>
          <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">sudo nano /etc/udev/rules.d/50-jdslabs-dfu.rules</pre>
        </li>
        <li>
          <strong>Add these rules to the file to reference the Element IV DFU interface:</strong>
          <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">
            {"SUBSYSTEM==\"usb\", ATTR{idVendor}==\"152a\", ATTR{idProduct}==\"88fa\", TAG+=\"uaccess\"\n"}
            {"SUBSYSTEM==\"usb\", ATTR{idVendor}==\"152a\", ATTR{idProduct}==\"88fc\", TAG+=\"uaccess\""}
          </pre>
        </li>
        <li>
          <strong>Reload the rules and apply changes:</strong>
          <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">
            sudo udevadm control --reload-rules{"\n"}
            sudo udevadm trigger
          </pre>
        </li>
        <li>
          <strong>Unplug and reconnect your device</strong>
        </li>
        <li>
          If issues persist, try rebooting your system:
          <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">sudo reboot</pre>
        </li>
      </ol>

      <div className="mt-6 pt-4 border-t border-mine-shaft">
        <p className="text-spun-pearl mb-2">
          <strong>Note:</strong> While it is entirely safe to keep these permissions enabled for future firmware updates, you may remove them using the following:
        </p>
        <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">sudo rm /etc/udev/rules.d/50-jdslabs-dfu.rules && sudo udevadm control --reload-rules</pre>
      </div>
    </div>
  )
}

const FaqPage = () => {
  return (
    <Layout>
      <div className="relative w-full flex-1 overflow-y-auto px-20 py-14">
        <h3 className="type-heading-2xl font-bold">Frequently Asked Questions</h3>
        
        <div className="type-body-lg mt-5">
          Find answers to common questions and setup instructions below.
        </div>

        <Accordion
          className="mb-32 mt-12"
          defaultExpandedKeys={new Set([])}
          items={[
            {
              title: "Supported Browsers",
              content: (
                <div className="space-y-4">
                  <p>JDS Labs Core is supported on the following desktop browsers:</p>
                  <ul className="list-disc pl-5">
                    <li>Google Chrome</li>
                    <li>Chromium</li>
                    <li>Microsoft Edge</li>
                    <li>Opera</li>
                    <li>Brave</li>
                    <li>Firefox* <span className="text-sm italic">requires the <a href="https://addons.mozilla.org/en-US/firefox/addon/webserial-for-firefox/" className="text-red hover:underline" target="_blank" rel="noopener noreferrer">WebSerial for Firefox</a> extension</span></li>
                  </ul>
                  <p className="text-sm italic mt-4">Note: Safari and mobile browsers are not currently supported due to WebUSB limitations.</p>
                </div>
              )
            },
            {
              title: "Linux: Unable to connect",
              content: (
                <div className="space-y-4">
                  <p>Linux distros require the following permissions changes to see the serial device:</p>
                  <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">sudo usermod -aG dialout,uucp $USER</pre>
                  <p>For Chromium, you may also need to run the following:</p>
                  <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">sudo snap connect chromium:raw-usb</pre>

                  <p className="mt-2">You can verify that your Element IV serial device is recognized by running:</p>
                  <pre className="mt-1 p-2 bg-jaguar rounded border border-mine-shaft">ls /dev/tty*</pre>
                </div>
              )
            },
            {
              title: "Linux: How to update Element IV Firmware",
              content: <LinuxUdevInstructions />
            }
            // Add more FAQ items here as needed
          ]}
        />
      </div>
    </Layout>
  )
}

export default FaqPage 